const Bdata = [
    {
       
        Stitle:"wea.png",
        Title:"Weather App",
       link:"https://weaterweb.netlify.app/",
        
     
    },
    {
       
        Stitle:"nab.png",
        Title:"Nabin Upreti",
       link:"https://nabinupreti.netlify.app/",
        
     
    },
    {
       
        Stitle:"web.png",
        Title:"WebDeveloper",
       link:"https://nawrajj.github.io/WEBDEVELOP/",
        
     
    },
    {
    Stitle:"naw.png",
    Title:"Nawaraj Pathak",
   link:"https://www.nawarajpathak.com.np",
    
 
}
    
    ];
    
    export default Bdata;