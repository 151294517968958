import React from 'react';
import {Link} from "react-router-dom";
import './navbar.css';
import Menubar from './Menu';

const Navbar = (props) =>{
  

  
    return(
  <>
<div >
                  <nav >
                  
                  <Link to="/"><label className='logo'>{props.title}</label></Link>
                  
                  <i id='mode' onClick={()=>props.call()}  className="fa fa-moon-o"></i>
                  <ul >
                  <li>     
         <Link to="/" >Home </Link> 
         </li>
         <li>
           <Link to="/About">About</Link>
           </li>
           <li>
           <Link to="/Content">Content</Link>
           </li>
           <li>
            <Link to="/Feedback">Feedback</Link>
            </li>

                      </ul>  
                     
               <Menubar  />
               
                </nav>
           
           </div>
  </>
  )
  }

  export default  Navbar;