import React from 'react';
import   './content.css';
function Box(props){
    return(
  <>
  
  <div id="box">
  
  <img className='webi'  src={props.Stitle}></img>
  <br/>
  <br/>
  <n className="title">{props.title}</n>
              <div>
              

              <a href={props.Link} target='_blank' >
              <button className='butt'>Visite Site</button>
              </a>
 
 
            </div>
  
  </div>
  
  </>
  );
  };
  
  export default  Box;