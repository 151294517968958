import React from 'react';
import './about.css';
const About = () =>{

    return(
  <>
  <div className='about'>
 <br/>

 <br/>
 <div  className='about' >
 <h1> About ME </h1>
               
<p>  I’m a Frontend Web Developer and tech enthusiast,
 I thrive on transforming ideas into captivating digital experiences.
  My journey began back in grade 8 when I first delved into the world of computers.
   Fast forward to high school, where I chose Computer Science as my +2 level subject,
    and now I’m pursuing a Bachelor’s degree in Information Technology (BIT).
<br/>
<br/>
 I revel in creating responsive layouts, styling elements, and breathing life into designs. From pixel-perfect details to seamless animations, I’m all about that frontend magic.

Tech isn’t just a career path for me; it’s a passion.  Let’s turn ideas into reality. 🚀
 </p>
<br/>
<br/>
<br/>
 <h3>My Skills</h3>
 <h4> Language</h4>
{/* skill card */}
<div className='sbox' >
 <div id='sbox'>
<i className='fa fa-html5' id='fa'></i>
 <h3>HTML</h3>
 </div>
 <div id='sbox'>
 <i className='fa fa-css3' id='fa'></i>
 <h3>CSS</h3>
 </div>

 <div id='sbox'>
 <img   width={145} src='c.png'></img>
 <h3>C</h3>
 </div>

 <div id='sbox'>
 <img   width={195} src='js.png'></img>
 <h3>JS</h3>
 </div>
 </div>
 
 <h4> Libarary </h4>
 <div id='sbox'>
 <img  className='html' src='logo512.png'></img>
 <h3>React JS</h3>
 </div>

 <h4> Tools </h4>
 <div id='sbox'>
 <img  className='html' src='vs.png'></img>
 <h3>Visual stdio code</h3>
 </div>

 <div id='sbox'>
   <img  className='html' src='gi.png'></img>
 <h3>Github</h3>
 </div>


<br/>
<h4>My Certificate</h4>

<img className='images' src='/image/HTML.jpg' border="2px" /> 

<img className='images' src='/image/css.jpg' border="2px"/> 

<img className='images' src='/image/tech.jpg' border="2px"/> 

<img className='images' src='/image/js.jpg' border="2px"/> 
</div>
</div>

        </>
  );
  };
  
  export default  About;

