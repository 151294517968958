import React, { useState } from 'react';
import './content.css';
import Box from './Box';
/*import Html from './components/Html';
import Css from './components/Css';
import Web from './components/Web';
import Js from './components/Js';*/

import Bdata from './Bdata';
function Content (){
  const [page,setPage]=useState();
 
  

    return(

      
  <>
 <div   className="content">
 
    <br/>
    <br/>
<br/>
    <br/>
    
<h1> Project</h1>
<h4>Mini Project</h4>
<div className='box'>
<n>Calculator</n>
<br/>
<br/>
<br/>
<a href="https://nawrajj.github.io/mycalculator/" target='_blank' >
              <button className='butt'>Visite Site</button>
              </a>
</div>


<div className='box'>
<n>FB UI Clone</n>
<br/>
<br/>
<br/>    

<a href="https://nawrajj.github.io/facebookclone/" target='_blank' >
              <button className='butt'>Visite Site</button>
              </a>
</div>

<div className='box'>
<n>Todo </n>
<br/>
 <br/>
 <br/>
<a href="https://nawrajj.github.io/Todo/" target='_blank' >
              <button className='butt'>Visite Site</button>
              </a>


</div>

<div className='box'>
<n>KPL</n>
<br/>
<br/>
<br/> 
<a href="https://nawrajj.github.io/KACHANKAWAL-/?fbclid=IwZXh0bgNhZW0CMTAAAR3SEf3GirMibiLnTaRteQgE2nHMw_Omwrl9Hfo3s0I9vENj_dR0jMWWhvo_aem_AYoUv7ntvkfFZFdYUMy0oEtB6dieeo1wiMzBG7LvkVCOQg82_L6DWSCNdCMYxcFvtGTNRbcsBNIqWE8mWl0rfe8Y" target='_blank' >
              <button className='butt'>Visite Site</button>
              </a>



</div>
<br/>




<h4>Main Project</h4>

{Bdata.map (function nBox(val){

return(
<Box 

Stitle={val.Stitle}
title={val.Title}
Link={val.link}
/>
)
}
)
}

{/*<Web  setPage={setPage}/>
<div className='box'>

{page==='Html' &&  <Html/>  } 

</div> */}


</div>
 

        </>
  );
  };
  
  export default  Content;