import React from 'react';
import './feedbaack.css';
const Feedback = () =>{
    return(
  <>

<section10 className="div">


<form action="mailto:nawarajpathak14@gmail.com" method="post" enctype="text/plain">
<br/>
<br/>
<h5>Your Feedback is important for me </h5>

<div className='f'>
       <label> Name </label><br/>
        <input type="text" name="Name" required className='Input'  placeholder="Enter your Name here"/>
     
   
      <br/>
      <input type="radio" className="radio" name="gender" value="male"/> <label>Male</label><br/>
      <input type="radio" for="va" id='va' name="gender" value="female"/> <label>Female</label>
      <br/>

      <label>Email:</label> <br/>
       <input type="E-mail" className='Input'  required   name="mail" placeholder="G-mail address"/>
      
      <br/>
        <label>Comment :</label><br/>        
        <textarea rows="6" cols="60" className='Co'   name="commentfield"></textarea>
  <br/>
  <br/>
        <input TYPE="submit" className="but" value="Send Feedback"/>
        <input TYPE="reset"  className="but" value="Reset"/>
       
       </div>   
</form>

</section10>
</>
  );
  };
  
  export default  Feedback;