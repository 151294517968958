import React,{useState} from 'react';

import {
   BrowserRouter as Router,
    Routes,
     Route, 
    Link
   } from "react-router-dom";
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Content from './components/Content';
import About from './components/About';
import Footer from './Footer';
import Feedback from './components/Feedback';



function App(props) {


  const [myStyle, setMyStyle]=useState({
  
    })

    const [b, setB]=useState("")

const  toggle=()=>{
if(myStyle.color=='white'){
  setMyStyle({
      color:'black',
      backgroundColor:'#f9f4f1dc'
})
setB({
  color:"white"
  
})}
else {
  setMyStyle(
    {
      color:'white',
      backgroundColor:'#555554'
})
}}

  
  return (
  <>
  <div className='d' style={myStyle}>
 
  <Router>
  
<Navbar title="NAWARAJ PATHAK" call={()=>toggle()} />


<Routes >
        
        <Route exact path="/" element={ <Home title="NAWARAJ PATHAK"/>}>   </Route>
      
        <Route path="/Content" element={  <Content/>} >  </Route>

        <Route path="/About" element={  <About  />} >  </Route>
        <Route path="/Feedback" element={  <Feedback />} >  </Route>
    
      </Routes>
     
    </Router>

    
    <Footer    />

    </div>
   </>
   
  );
}


export default App;
