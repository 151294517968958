import React from 'react';
import './footer.css';

const Footer = (props) =>{
    
    return(
  <>


        <div className="footer " data-aos="fade-in" data-aos-duration="1000">
            <div className="container-fluid">
                <div className="container">
                    <div className="footer-info">
                        <h2>NEPAL</h2>
                        <h3>Kachankawal, Jhapa, Koshi Province</h3>
                        <div className="footer-menu">
                            <p>+9779806091623</p>
                            <p >nawrajpathak@gmail.com</p>
                        </div>
                        
                        <div className="footer-social">
                         
                            <a href= " https://www.facebook.com/nwj.ptk" target='/blank' className="fa fa-facebook"></a>
                            <a href="https://github.com/nawrajj"  target='/blank'className="fa fa-github"></a> 
                            <a href="https://wa.me/9806091623 " className="fa fa-whatsapp"></a>
                            <a href="https://youtube.com/@Webdeveloper12345" target='/blank' className="fa fa-youtube"></a> 
                            <a href="https://youtube.com/@Webdeveloper12345" target='/blank' className="fa fa-linkedin"></a> 
                            
                        </div>
                    </div>
                   
                </div>
                <div class="container copyright">
             
                 
                  
                    <c>&copy; All Right Reserved | Developed By <a href="https://nawarajpathak.com.np">Nawaraj</a></c>

                    
                    
                    
                  
                </div>

                <a className="btn" href="#top">Back🔝Top </a>
                <br/>
                <br/>
            </div>
        </div>
        
      
    


    
                <a name="bottom"></a>                          


                </>
  );
  };
  
  export default Footer;
