import React from 'react';
import {Link} from "react-router-dom";

import './navbar.css';
//we use same css.
const Menubar = () =>{

  function btn(){
    document.querySelector(".openul").style.display = "block";
    document.querySelector("#bar").style.display = "none";
    document.querySelector("#closebar").style.display = "flex"
  }
  function btnn(){
    document.querySelector(".openul").style.display = "none";
    document.querySelector("#bar").style.display = "flex";
    document.querySelector("#closebar").style.display = "none"

  }
    return(
  <>

              
               
                      
                      <ul  onClick={btnn} className='openul' >
                      
                      
                      <br/>
                      <br/>
                  
                  <li>     
         <Link to="/" >Home </Link> 
         </li> <br/>
         <li>
           <Link to="/About">About</Link>
           </li> <br/>
           <li>
           <Link to="/Content">Project</Link>
           </li> <br/>
           <li>
            <Link to="/Feedback">Feedback</Link>
            </li> <br/>       
                      </ul>
                    
                      <div className="toggle">
               
                <i id='bar' onClick={btn} className="fa fa-bars" aria-hidden="true"></i>
                <i id='closebar' onClick={btnn} class="fa fa-times" aria-hidden="true"></i>
               
               </div>
         
  </>
  )
  }

  
  export default  Menubar;