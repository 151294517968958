import React from 'react';
import {Link} from "react-router-dom";
import './home.css';
const Home = (props) =>{

    return(
      
  <>
  
 <div
  className="home"    >
 
    <br/>
    
    <br/>
    <br/>
    <br/>
    <Link to="/"><img src="https://i.ibb.co/nmGh2V3/IMG-2023-04-05-091225.png"  height="150px"/></Link>
    
<div className="div">
    
    


    <span>नमस्ते
    🙏!</span>

  <br/>
  <span>I am <br/>  {props.title} </span>

  
</div>
        <div>   
         <k> I am   FRONTEND WEB DEVELOPER & Computer Student. I love Doing work in Tech <Link className='learn' to="/About">Learn More About Me</Link></k> 
        
                
<br/>



<br/> 

    <button className='butt' >Download My C.V</button> 
 
 <a href="https://www.nawrajpathak14@gmail.com" target="_blank"> 
 <button type='sumbit'  className='butt'>Hire Me</button>
</a>

</div>
</div>

        </>
  );
  };
  
  export default  Home;